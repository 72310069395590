import React, { Component } from 'react'
import BannerImg from "./healthcarebanner1.jpg"
//'./../components/health-care-banner1.jpg'
class Banner extends Component {
    render() {
        return (
            <>
                {/* <img src={BannerImg} />


                <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" className="active"></li>
                    </ol>
                </div>
                <div className="carousel-inner">
                    <div className="carousel-item">
                        <div className="page-header page-header-dark bg-img-cover overlay"
                            style={{ backgroundImage: 'url(/images/healthcarebanner1.jpg)' }}>
                        </div>

                    </div>
                </div> */}
                <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" class=""></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1" class="active"></li>
                    </ol>
                    <div class="carousel-item">
                        <div class="page-header page-header-dark bg-img-cover overlay" style={{ backgroundImage: 'url(./img/health-care-banner1.jpg)' }}>
                            <div class="page-header-content py-10">
                                <div class="container">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <div data-aos="fade-up" class="mb-4 aos-init aos-animate">
                                                <h1 class="page-header-title">
                                                    Your Health <b>
                                                        is always <br /> in the
                                                        first
                                                        place.
                                                    </b>
                                                </h1>
                                                <p class="page-header-text">
                                                    The greatest asset in any organization
                                                    should be its
                                                    people.<br />We
                                                    make sure it is.
                                                </p>
                                            </div>
                                            <button class="btn btn-marketing btn-primary aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                                Learn More  <i class="fas fa-arrow-right  ml-2"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div><div class="carousel-inner">

                        <div class="carousel-item active">
                            <div class="page-header page-header-dark bg-img-cover overlay" style={{ backgroundImage: 'url(./img/bg-2.jpg)' }}>
                                <div class="page-header-content py-10">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-12">

                                                <div data-aos="fade-up" class="mb-4 aos-init aos-animate">
                                                    <h1 class="page-header-title">
                                                        Your Health <b>
                                                            is always <br /> in the
                                                            first
                                                            place.
                                                        </b>
                                                    </h1>
                                                    <p class="page-header-text">
                                                        The greatest asset in any organization
                                                        should be its
                                                        people.<br />We
                                                        make sure it is.
                                                    </p>
                                                </div>
                                                <button class="btn btn-marketing btn-primary aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
                                                    Learn More  <i class="fas fa-arrow-right ml-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}


export default Banner;
import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Home.css";
import logo from "./BHS.svg";
import { useOktaAuth } from "@okta/okta-react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Button } from "react-bootstrap";
// import { Bid } from "./img/lungs.png"

const NavbarCom = (props) => {
  // debugger;
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          console.log(info);
          setUserInfo(info);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const login = async () => navigate("/login");
  const logout = async () => {
    try {
      await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <nav className="navbar navbar-marketing navbar-expand-lg bg-white navbar-light shadow ">
      <div className="col-12">
        <div className="row text-primary py-3 mb-4 bg-white shadow shadow-bottom-sm d-none d-lg-flex nav-top">
          <div className="col-sm">
            <span className="mr-4">
              <i className="far fa-clock"></i>
              {props.isUserCreated && <p>You are successfully regsitered</p>}
              Mon - Sun 0900 - 2100
            </span>
            <span className="mr-4">
              <i className="fas fa-phone-alt"></i>
              +1-2345-3455-33
            </span>
            <span>
              <i className="far fa-envelope"></i>
              contact@beyondid.com
            </span>
          </div>
          <div className="col-12 col-sm-auto">
            <a href="#" className="px-2">
              {" "}
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="px-2">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="#" className="px-2">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="px-2">
              {" "}
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
        <div className="row align-items-center d-none d-lg-flex mb-3">
          <div>
            <a className="app-logo" href="/">
              <img src={process.env.REACT_APP_SERVER_URL} alt="" className="" />
            </a>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <img
                height="48px"
                class="mr-2"
                src="./img/Bidlogo.png"
                alt=""
              ></img>
              <div className="d-flex">
                <div className="header-info">
                  <i className="far fa-check-circle"></i>
                  <h4 className="text-primary font-weight-bold mb-0">
                    Trusted by
                  </h4>
                  <p className="mb-0">120,0000+ People</p>
                </div>
                <div className="header-info">
                  <i className="far fa-hospital"></i>
                  <h4 className="text-primary font-weight-bold mb-0">
                    Best Hospital
                  </h4>
                  <p className="mb-0">CNN News</p>
                </div>
                <div className="header-info">
                  <i className="fas fa-award"></i>
                  <h4 className="text-primary font-weight-bold mb-0">
                    Number #1
                  </h4>
                  <p className="mb-0">Clinic in New York</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row align-items-center justify-content-between mb-2"
          id="sticky_nav"
        >
          <div className="col">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav align-items-center">
                <li className="d-lg-none">
                  <a
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fas fa-times"></i>
                  </a>
                  {/* <img src={Bid} alt="Beyondid logo" /> */}
                </li>
                <li className="nav-item">
                  <a className="nav-link active" href="../index">
                    Find a Doctor
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="../index">
                    Care &amp; Treatment
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="../index">
                    Health &amp; Wellness
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="../index">
                    Patient &amp; Visitors
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="../index">
                    Locations
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link">Pharmacy</a>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Our System
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right"
                    aria-labelledby="navbarDropdown"
                  >
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-auto d-flex align-items-center">
            {/* <a data-toggle="modal" className="mr-3" data-target="#search-modal">
              <i className="fas fa-search"></i>
            </a> */}
            {!authState ||
              (!authState.isAuthenticated && (
                <div className="">
                  <Link to="/register" className="btn btn-primary mr-2">
                    Register
                  </Link>
                </div>
              ))}
            {!authState ||
              (!authState.isAuthenticated && (
                <button className="btn btn-primary" onClick={login}>
                  Login
                </button>
              ))}
            {authState && authState.isAuthenticated && userInfo && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  id="welcome"
                  style={{ marginRight: "10px", marginBottom: "0" }}
                >
                  Welcome, {userInfo.FirstName} {userInfo.LastName}
                </p>
                <button className="btn btn-primary" onClick={logout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavbarCom;

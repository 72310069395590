import React, { Component } from 'react'
class Footer extends Component {
    render() {
        return (
            <>
                <section class="footer-section">
                    <div class="container-fluid px-0">
                        <div class="row no-gutters">
                            <div class="col-md-4">
                                <div class="h-100 px-4 py-5  bg-primary">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <i class="fas fa-map-marker-alt"></i>
                                        </div>
                                        <div class="col pl-0">
                                            <h3 class="mb-0">Get directions</h3>
                                            <p class="mb-0 text-primary-light">Find us on map</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="h-100 px-4 py-5 " style={{ background: "#05296f" }}>
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <i class="fas fa-envelope-open-text"></i>
                                        </div>
                                        <div class="col pl-0">

                                            <h3 class="mb-0">Contact & Appointment</h3>
                                            <p class="mb-0 text-primary-light">Send us an email</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="h-100 px-4 py-5  bg-primary">
                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <i class="fas fa-phone-alt"></i>
                                        </div>
                                        <div class="col  pl-0">
                                            <h3 class="mb-0">Emergency Service</h3>
                                            <p class="mb-0 text-primary-light">Call : +1-2345-3455-33</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <footer class="footer pt-5 mt-auto bg-black footer-dark">
                    <div class="container">
                        <div class="row pb-5">
                            <div class="col-md-3">
                                <div class="footer-brand"><img height="50" src={process.env.REACT_APP_SERVER_URL} /></div>
                                <div class="my-4">
                                    <p class="font-weight-bold mb-4">
                                        535 Mission St <br />14th floor
                                        San Francisco,<br /> CA 94105, USA
                                    </p>

                                    <div class="mb-3 ">
                                        <i class="fas fa-phone-alt mr-2"></i>
                                        +1-2345-3455-33
                                    </div>
                                    <div class="mb-5 ">
                                        <i class="far fa-envelope mr-2"></i>
                                        contact@beyondid.com
                                    </div>

                                </div>
                                <div class="icon-list-social">
                                    <a class="icon-list-social-link mr-3" href="javascript:void(0);">
                                        <i class="text-primary fab fa-instagram"></i>
                                    </a>
                                    <a class="icon-list-social-link mr-3" href="javascript:void(0);">
                                        <i class="text-primary fab fa-facebook"></i>
                                    </a>
                                    <a class="icon-list-social-link mr-3" href="javascript:void(0);">
                                        <i class="text-primary fab fa-github"></i>
                                    </a>
                                    <a class="icon-list-social-link " href="javascript:void(0);">
                                        <i class="text-primary fab fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h4 class="font-weight-bold mb-4">About Us</h4>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Our Mission & Values</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Leadership</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Transformation</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Awards</a></li>
                                    <li class="list-group-item px-0 py-2 border-0">
                                        <a href="#">Diversity is Our Specialty</a>
                                    </li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Publications & Reports</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Policies & Procedures</a></li>
                                </ul>
                            </div>
                            <div class="col-md-3 mt-5 mt-md-0">
                                <h4 class="font-weight-bold mb-4">Our Services</h4>
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Lung Diseases</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Orthopaedic</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Pharmacy</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Sport Injury</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Heart</a></li>
                                    <li class="list-group-item px-0 py-2 border-0"><a href="#">Dental Service</a></li>

                                </ul>
                            </div>
                            <div class="col-md-3 mt-5 mt-md-0">
                                <h4 class="font-weight-bold mb-4">Hospital Hours</h4>
                                <ul class="list-group list-group-flush text-primary">
                                    <li class="list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0">
                                        <span><i class="far fa-clock mr-2"></i> Monday-Friday</span>
                                        <span>08:00-20:00</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0">
                                        <span><i class="far fa-clock mr-2"></i>Saturday</span>
                                        <span>08:00-18:00</span>
                                    </li>
                                    <li class="list-group-item d-flex justify-content-between align-items-center font-weight-bold px-0">
                                        <span><i class="far fa-clock mr-2"></i>Sunday</span>
                                        <span>08:00-18:00</span>
                                    </li>

                                </ul>
                            </div>


                        </div>
                    </div>
                    <div class="py-4 bg-white shadow">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-md-6 small">Copyright &copy; BeyondID Health System</div>
                                <div class="col-md-6 text-md-right small">
                                    &middot;
                                    <a href="javascript:void(0);" class="mr-3">Privacy Policy</a>
                                    &middot;
                                    <a href="javascript:void(0);">Terms &amp; Conditions</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}


export default Footer;





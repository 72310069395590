import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { showErrorToast } from "./Toast";
// import { AcuantCamera, AcuantCameraUI } from '../webSdk/AcuantCamera.js';
// import { AcuantJavascriptWebSdk } from '../webSdk/AcuantJavascriptWebSdk.min.js';
// import { AcuantPassiveLiveness } from '../../public/lib/webSdk';

const Acuant = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  base64BackImageData,
  setBase64BackImageData,
  base64FrontImageData,
  setBase64FrontImageData,
  base64SelfieImageData,
  setBase64SelfieImageData,
}) => {
  const [documentType, setDocumentType] = useState(null);
  const [frontImageChosen, setFrontImageChosen] = useState(false);
  const [backImageChosen, setBackImageChosen] = useState(false);
  const AcuantJavascriptWebSdk = window.AcuantJavascriptWebSdk;
  const AcuantCamera = window.AcuantCamera;
  const AcuantCameraUI = window.AcuantCameraUI;

  // const [base64FrontImageDataToDisplay, setbase64FrontImageDataToDisplay] =useState(null);

  const [isLoading, setIsloading] = useState(false);



  const navigate = useNavigate();

  const cameraBtn = document.getElementById("camera-btn");
  const camera = document.getElementById("acuant-camera");

  var currentResult = {};

  const faceCaptureContainer = document.getElementById(
    "acuant-face-capture-container"
  );

  var liveCaptureFailed = false;

  //Good practice to end workers when you are done with them (does not have to be in onbeforeunload, end them when you are done with them in your workflow and won't use them again).
  window.onbeforeunload = function (event) {
    end();
  };

  function showCameraButton() {
    cameraBtn.style.display = "block";
  }

  function closeCamera() {
    // camera.style.display = 'none';
    // result.style.display = 'block';
    // cameraBtn.style.display = 'block';
    // desc.style.display = 'block';
  }

  useEffect(() => {
    if (documentType) startCamera();
  }, [documentType]);
  function openCamera(topDocumentType) {
    // desc.style.display = 'none';
    // result.style.display = 'none';
    // faceButtons.style.display = 'none';
    // resultFace.style.display = 'none';
    setDocumentType(topDocumentType);
  }

  const options = {
    text: {
      NONE: "ALIGN",
      SMALL_DOCUMENT: "MOVE CLOSER",
      GOOD_DOCUMENT: null, //null countdown
      BIG_DOCUMENT: "TOO CLOSE",
      CAPTURING: "CAPTURING",
      TAP_TO_CAPTURE: "TAP TO CAPTURE",
    },
  };

  var cameraCallback = {
    onCaptured,
    onCropped,
    onFrameAvailable: (response) => {
    },
    onError,
  };

  function startCamera() {

    window.init();
    currentResult = {};
    if (AcuantCamera.isCameraSupported && !liveCaptureFailed) {
      cameraBtn.style.display = "none";
      camera.style.display = "block";
      AcuantCameraUI.start(cameraCallback, options);
    } else {
      startManualCapture();
    }
  }

  function onError(error, code) {
    camera.style.display = "none";
    faceCaptureContainer.style.display = "none";
    console.error(error, code);
    liveCaptureFailed = true;

    if (code === AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) {
      //live capture was started after previous failure leading to manual capture, show any elements you may have hidden as the user can cancel the prompt and could otherwise end up on a blank page.
      cameraBtn.style.display = "block";
    } else {
      modal.style.display = "block";
      switch (code) {
        case AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE: //camera froze/crashed; usually happens due to an ios 15 bug
          modalText.innerHTML = "Live Camera failed. Start manual capture.";
          break;
        case AcuantJavascriptWebSdk.START_FAIL_CODE: //camera not supported or permission not granted
          modalText.innerHTML =
            "Live Camera failed to open. Start manual capture.";
          break;
        case AcuantJavascriptWebSdk.HEIC_NOT_SUPPORTED_CODE:
          modalText.innerHTML = "HEIC image failed to process.";
          break;
        default: //unknown error (shouldn't happen)
          modalText.innerHTML = "Unknown camera failure. Start manual capture.";
          break;
      }
    }
  }

  function onCaptured(response) {
    // cameraBtn.style.display = 'none';
    // modal.style.display = 'none';
    // loader.style.display = 'block';
    console.log("onCaptured response", response);
  }

  function onCropped(response) {
    // console.log('documentType in onCropped', documentType);
    setDocumentType(null);
    if (!response) {
      alert("Wrong image!!");
    }
    console.log("onCropped response", response);
    console.log("documentType on onCropped:", documentType);
    if (documentType === "back") {
      console.log("back");
      const baseBack64ImageDataResponse = response.image.data;
      const [, base64BackImageData] = baseBack64ImageDataResponse.split(",");
      // props.onCroppedCallback(base64BackImageData, documentType);
      return;
    } else {
      console.log("front");
      const baseFront64ImageDataResponse = response.image.data;
      const [, base64FrontImageData] = baseFront64ImageDataResponse.split(",");
      // setbase64FrontImageDataToDisplay(base64FrontImageData);
      // props.onCroppedCallback(base64FrontImageData, documentType);
      setBase64FrontImageData(base64FrontImageData);
      return;
    }
  }

  function startManualCapture() {
    // debugger;
    AcuantCamera.startManualCapture(cameraCallback);
  }

  function end() {
    AcuantJavascriptWebSdk.end();
  }

  //**************** Face Match Example ==========================

  let face1;
  let face3;
  let face4;

  function facesSelected(e) {
    const inputs = document.getElementById("face-button-1").files[0];


    loadImage(inputs);
    //this is for front image function
    setFrontImageChosen(true);
  }



  function loadImage(imageFile) {

    var reader = new FileReader();
    reader.onload = function (e) {
      face1 = e.target.result.replace("data:image/jpeg;base64,", "");

      setBase64FrontImageData(face1)

    };
    reader.readAsDataURL(imageFile);

  }

  function facesSelected1(e) {
    const inputs = document.getElementById("back-button-1").files[0];

    loadImage1(inputs);
    setBackImageChosen(true);
  }

  function loadImage1(imageFile) {
    var reader = new FileReader();
    reader.onload = function (e) {
      face3 = e.target.result.replace("data:image/jpeg;base64,", "");

      setBase64BackImageData(face3);
    };
    reader.readAsDataURL(imageFile);
  }

  //**************** Custom Modal===========================
  // Get the modal
  const modal = document.getElementById("acuant-modal");
  const modalText = document.getElementById("acuant-modal-text");

  document.addEventListener("DOMContentLoaded", function () {
    var span = document.getElementsByClassName("close")[0];
    if (span) {
      span.onclick = function () {
        closeCamera();
        modal.style.display = "none";
      };
    } else {
      console.error('No element with className "close" found');
    }
  });

  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function (event) {
    if (event.target == modal) {
      closeCamera();
      modal.style.display = "none";
    }
  };
  //**************** Custom Modal===========================

  const acuantConfig = {
    path: "/webSdk/",
    jpegQuality: 1.0, //strongly advised not to modify (can be left out will default to 1.0)
  };

  // const clickHandler = () => {
  //   console.log(
  //     'base64FrontImageDataToDisplay:',
  //     base64FrontImageDataToDisplay
  //   );
  //   var copyText = document.getElementById('idText');
  //   // Select the text field
  //   copyText.select();
  // };

  function handleNextStep() {
    // Validate that both front and back images are chosen before proceeding
    if (frontImageChosen && backImageChosen) {
      // Perform additional validation or actions if needed
      // Call the nextStep function if all conditions are met
      nextStep();
    } else {
      // Display an error message or handle the case where images are not chosen
      showErrorToast("Please choose both front and back images.");
    }
  }

  return (
    <>
      <div className="doc-upload-parent">
        <div className="document-upload-form">
          <h1 className="upload-document-title">Upload Document</h1>
          <p className="upload-document-para">
            Please upload the document you want to use for verification.
          </p>
          <div
            id="acuant-loader"
            className="row text-center"
            style={{ display: "none" }}
          >
            <div className="col-md-12">
              <div className="loader"></div>
            </div>
          </div>
          <div id="camera-btn" className="row text-center">
            {/* <div className=' btn-group'>
          <button
            className='btn btn-success mr-2'
            onClick={(e) => {
              openCamera('front');
            }}
          >
            Upload Front Document
          </button>
        </div> */}
            {/* <button onClick={clickHandler}>Get Front Data</button> */}
            <div className="document-upload-section mx-2">
              <div
                id="face-buttons"
                className=" btn-group d-flex align-items-center"
              >
                <label htmlFor="file">Choose Front image</label>
                <input
                  id="face-button-1"
                  className="btn btn-light rounded ml-2"
                  type="file"
                  multiple={false}
                  accept="image/jpeg"
                  onChange={facesSelected}
                />
              </div>
              <div
                id="face-buttons"
                className=" btn-group d-flex align-items-center"
              >
                <label htmlFor="file">Choose Back image</label>
                <input
                  id="back-button-1"
                  type="file"
                  className="btn btn-light rounded ml-2"
                  accept="image/jpeg"
                  multiple={false}
                  onChange={facesSelected1}
                />
              </div>

              <p>Allowed Formats: JPG, PNG, JPEG.</p>
            </div>
          </div>

          {/* {base64FrontImageDataToDisplay && (
        <textarea id='idText'>{base64FrontImageDataToDisplay}</textarea>
      )} */}

          {/* <div
            id="acuant-face-capture-container"
            className="face-camera-container"
          >
            <div id="face-detection-text" className="detection-state"></div>
          </div> */}
          {/* <div id="acuant-camera"></div> */}
          <div id="result-id" style={{ display: "none" }}>
            <div className="row text-center">
              <div className="col-md-12">
                <p id="result-error">
                  An error has occurred while cropping the image. Please try
                  again.
                </p>
                <img
                  id="result-id-img"
                  className="fit-screen"
                  style={{ display: "block" }}
                ></img>
              </div>
            </div>
          </div>
          <div className="upload-document-buttons">
            <button
              className=" continue-phone rounded btn  btn-secondary cursor-not-allowed "
              disabled
            >
              Continue on phone
            </button>
            <button
              className="identity-button mt-0 btn rounded btn-primary"
              onClick={handleNextStep}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Acuant;

import './App.css';
import Home from './components/Home';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Register from './components/Register/Register';
import oktaSignInConfig from './components/OktaSignInConfig';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Auth from './components/Auth/Auth';
import Acuant from './components/Acuant';
import { ToastContainer } from 'react-toastify';
import SuccessfulRegister from './components/SuccessfulRegister';
import Stepper from './components/StepperForm/Stepper'
import SelectDocument from './components/SelectDocument/SelectDocument';



const auth = new OktaAuth(oktaSignInConfig);

const App = () => {
  const navigate = useNavigate();

  const triggerLogin = () => {
    navigate('/login');
  };
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = auth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      // setAuthRequiredModalOpen(true);
    }
  };
  return (

    <Security
      oktaAuth={auth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/login/callback' component={LoginCallback} />
        {/* <Route path='/register' element={<Register />} /> */}
        <Route path='/login' element={<Auth />} />
        <Route path='/acuant' element={<Acuant />} />
        <Route path='/SuccessfulRegister' element={<SuccessfulRegister />} />
        <Route path='/register' element={<Stepper />} />
        <Route path='/selectDocument' element={<SelectDocument />} />

      </Routes>
      <ToastContainer />
    </Security>
  );
};

export default App;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../components/Register/Register.css";
import "onfido-sdk-ui/dist/style.css";
import ApiService from "../../services/ApiService";
import useInterval from "../util/interval";

import { useFormik } from "formik";
import { Input, Switch } from "antd";
import { createOktaUser } from "../../services/ApiService";
import { showErrorToast, showSuccessToast } from "../Toast";
import * as Yup from "yup";
import { FormCheck, FormGroup, FormLabel } from "react-bootstrap";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(4, "Password must be at least 4 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm your password"),
});

const Registration = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  setCurrent,
  setshowStepper,
  showOnfidoView,
  setShowOnfidoView
}) => {
  const navigate = useNavigate();
  const [next, setNext] = useState(false);

  const createUser = async (values) => {
    try {
      // Create Okta user
      const oktaUser = {
        profile: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          login: values.email,
        },
        credentials: {
          password: {
            value: values.password,
          },

        },
        "groupIds": ["00ghey9da1PXRVcQI4x7"]
      };

      // Call the createOktaUser function
      const oktaResponse = await createOktaUser(oktaUser);
      console.log("Okta User Created:", oktaResponse);
      setIsUserCreated(true);
      navigate("/SuccessfulRegister");
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    } catch (error) {
      console.error("Error during Okta user creation:", error);

      // Check if the error is due to a pre-existing user
      if (
        error.response &&
        error.response.status === 400 &&
        error.response.data.errorCode === "E0000001" &&
        error.response.data.errorCauses.some((cause) =>
          cause.errorSummary.includes(
            "login: An object with this field already exists"
          )
        )
      ) {
        // Custom error message for user already exists
        showErrorToast("User already Exists!");
      } else if (error.message) {
        // Show a specific error message based on the error message
        showErrorToast(error.message);
      } else {
        // For other errors, show a generic error message
        showErrorToast("Failed to create user. Please try again later.");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: "",
      confirmPassword: "",
      acceptTerms: false,
    },
    validationSchema: RegisterSchema.concat(
      Yup.object().shape({
        acceptTerms: Yup.bool()
          .oneOf([true], "Please accept the terms and conditions")
          .required("Please accept the terms and conditions"),
      })
    ),
    onSubmit: async (values) => {
      if (next) {
        // set values
        setFirstName(values.firstName);
        setLastName(values.lastName);
        setEmail(values.email);
        setPassword(values.password);

        nextStep();
      } else {
        createUser(values);
      }
    },
  });
  useEffect(() => {
    // Check if firstName or lastName has changed
    if (formik.values.firstName !== firstName || formik.values.lastName !== lastName) {
      const { firstName, lastName } = formik.values;

      if (firstName !== '' && lastName !== '') {
        const email = `${firstName}.${lastName}@mailinator.com`;
        const username = `${firstName}.${lastName}`;

        // Update formik state
        formik.setFieldValue('email', email);
        formik.setFieldValue('username', username);

        // Also update the external state (if needed)
        setUsername(username);

        setValueRequired(false);
      } else {
        // Clear values if either firstName or lastName is empty
        formik.setFieldValue('email', '');
        formik.setFieldValue('username', '');
        setUsername(''); // Clear the external state as well
        setValueRequired(true);
      }
    }
  }, [firstName, lastName, formik.values.firstName, formik.values.lastName]);

  document.title = 'LexisNexis | Register'
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  useInterval(
    () => {
      if (!verifyState.isComplete && verifyState.isComplete !== undefined) {
        checkStatus(verifyState.checkId);
      }
    },
    !verifyState.isComplete ? 10000 : null
  );

  const checkStatus = (data) => {
    apiService
      .post("api/status", data)
      .then((resp) => {
        console.log("respo -> ", resp);
        if (resp.data.checkStatus === "complete") {
          setVerifyState((prevState) => ({
            ...prevState,
            isComplete: true,
            result: resp.data.checkResult,
          }));
        }
      })
      .catch((err) => console.log("130 err -<> ", err));
  };

  const onNext = () => {
    if (showOnfidoView) {
      navigate("/acuant", {
        state: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
        },
      });
    }
  };

  const onToggleSwitch = () => {
    setShowOnfidoView(!showOnfidoView);
    setshowStepper(!showOnfidoView);
  };

  return (
    <div className="registration-form rounded-bottom">
      <h3 className="registration-form-title text-left mt-3  pt-1">
        Enrollment Form
      </h3>
      <p className="text-left">
        Please fill out the following fields to proceed to registration
      </p>
      <section className="section-register">
        <div className="login-container">
          <div className="login-panel" style={{ zIndex: 100 }} id="login">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="form-group col">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="First Name*"
                    id="fname"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  )}
                </div>

                <div className="form-group col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name*"
                    id="lname"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <div className="text-danger">{formik.errors.username}</div>
                  )}
                </div>
                <div className="form-group col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email*"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-danger">{formik.errors.email}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-sm-6">
                  <input
                    type="password"
                    className="form-control
                    "
                    placeholder="Password*"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-danger">{formik.errors.password}</div>
                  )}
                </div>

                <div className="form-group col-sm-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password*"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="text-danger">
                        {formik.errors.confirmPassword}
                      </div>
                    )}
                </div>
                <FormGroup className="text-center">
                  <FormCheck className="d-flex align-items-center ">
                    <Input
                      className="custom-consent-check"
                      type="checkbox"
                      id="acceptTermsCheckbox" // Added an id for the label association
                      name="acceptTerms"
                      checked={formik.values.acceptTerms}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormCheckLabel
                      className="custom-consent-label"
                      htmlFor="acceptTermsCheckbox"
                    >
                      <p className="consent-class">
                        I accept the terms and conditions.
                      </p>
                    </FormCheckLabel>
                  </FormCheck>
                  {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                    <div className="text-danger ml-4">
                      {formik.errors.acceptTerms}
                    </div>
                  )}
                </FormGroup>
              </div>
              <div className="text-center mt-4">
                <Switch onChange={onToggleSwitch} value={showOnfidoView} />
                <p>Identity Proofing</p>
              </div>

              <div className="d-flex justify-content-end">
                {!showOnfidoView && (
                  <button
                    onClick={onsubmit}
                    className="btn btn-primary w-25 btn-block rounded"
                  >
                    Submit
                  </button>
                )}
                {showOnfidoView && (
                  <button
                    onClick={() => {
                      setNext(true);
                    }}
                    className="btn btn-primary w-25 btn-block rounded"
                    id="submit"
                  >
                    Next
                  </button>
                )}
              </div>

              <span className="text-danger"></span>
              <input name="__RequestVerificationToken" type="hidden" />
            </form>
          </div>
          {showOnfidoView && <div id="onfido-mount"></div>}
        </div>
      </section>
    </div>
  );
};

export default Registration;
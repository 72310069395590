import React, { useState, useRef } from "react";
import LottieAnimation from "../../components/LottieAnimation";
import Animation from "../../lotties/Animation.json";
import { showErrorToast, showSuccessToast } from "../Toast";

import { useNavigate, useLocation } from "react-router-dom";
import ApiService from "../../services/ApiService";

const UploadFaceImage = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  base64BackImageData,
  setBase64BackImageData,
  base64FrontImageData,
  setBase64FrontImageData,
  base64SelfieImageData,
  setBase64SelfieImageData,
  setCurrent,
}) => {
  let face1;
  const [image, setImage] = useState(null);
  const [isAnimationVisible, setAnimationVisible] = useState(false);

  const inputRef = useRef();
  const apiService = new ApiService(process.env.REACT_APP_BASEURL);

  const handleTriggerClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    console.log(file);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
      // Use the logic from facesSelected and loadImage here
      loadImage(file);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  function loadImage(imageFile) {
    var reader = new FileReader();
    reader.onload = function (e) {
      face1 = e.target.result.replace("data:image/jpeg;base64,", "");
      setBase64SelfieImageData(face1);
    };
    reader.readAsDataURL(imageFile);
  }

  const navigate = useNavigate();

  const submitRegistration = () => {
    setCurrent(3); // Advance to the last step
  };

  const createOktaUser = () => {
    return new Promise((resolve, reject) => {
      const user = {
        profile: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          login: email,
          LexisNexis: "LexisNexis",
        },
        credentials: {
          password: {
            value: password,
          },

        },
        "groupIds": ["00ghey9da1PXRVcQI4x7"]
      };

      apiService
        .post("api/create", user)
        .then((resp) => {
          console.log("User Created:", resp);
          setIsUserCreated(true);
          submitRegistration();
          setTimeout(() => {
            navigate("/login");
          }, 3000);

          // Resolve with the response
          resolve(resp);
        })
        .catch((err) => {
          console.log("Error While creating Okta user ", err);

          if (
            err.response &&
            err.response.data &&
            err.response.data.message &&
            err.response.data.message.errorCauses[0] &&
            err.response.data.message.errorCauses[0].errorSummary
          ) {
            const errorSummary =
              err.response.data.message.errorCauses[0].errorSummary;
            console.log("Error Summary:", errorSummary);
            if (errorSummary.includes("login: An object with this field already exists")) {
              showErrorToast("User Already exists");
            } else {
              showErrorToast(errorSummary);
              // Handle other errors accordingly
            }
          } else {
            console.log("Failed to create user. Please try again later.");
          }

          // Reject with the error
          reject(err);
        });
    });
  };

  const onSubmit = async (data) => {
    let postValue = {
      frontData: base64FrontImageData,
      backData: base64BackImageData,
      selfie: base64SelfieImageData,
    };

    try {
      setAnimationVisible(true);
      showSuccessToast("The user is being created. Please wait for some time");

      const resp = await apiService.post(
        process.env.REACT_APP_ONFIDO_CREATE_USER,
        postValue
      );

      const createUserResponse = await createOktaUser();

      setAnimationVisible(false);
      nextStep();
    } catch (error) {
      console.log("Error is:", error);

      showErrorToast("Failed to create user. Please try again later.");

      setAnimationVisible(false);
    }
  };

  return (
    <div>
      {isAnimationVisible && <LottieAnimation />}
      <h1 className="face-recognition-title mt-3">Upload your face image </h1>
      <p className="mb-2">Please follow the notes below.</p>
      <ul className="pl-4">
        <li>Face forward and make sure your entire face is visible.</li>
        <li>Remove your glasses, if necessary.</li>
      </ul>

      {!image && (
        <button
          className="btn upload-face-image-btn btn-light rounded "
          onClick={handleTriggerClick}
        >
          Click Here to Upload
        </button>
      )}

      <input
        type="file"
        ref={inputRef}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />

      {image && (
        <div className="text-center">
          <img
            className="rounded selfie-thumbnail border-secondary text-center"
            width={150}
            height={150}
            src={image}
            alt="Uploaded Face Image"
          />
          <p className="mb-0"> Make sure your entire face is visible</p>
          <button
            className="btn-link btn"
            onClick={() => {
              setImage(null);
              inputRef.current.value = "";
            }}
          >
            Click Here to Change Image
          </button>
        </div>
      )}

      {image && (
        <button onClick={onSubmit} className="btn w-100 rounded btn-primary">
          Upload & Submit
        </button>
      )}
    </div>
  );
};

export default UploadFaceImage;

import React, { useState } from "react";
import { Button, Layout, message, Steps } from "antd";
import {
  UserOutlined,
  SolutionOutlined,
  FileDoneOutlined,
  SmileOutlined,
} from "@ant-design/icons";

import Registration from "../Steps/Registration";
import SelectDocument from "../SelectDocument/SelectDocument";
import UploadFaceImage from "../UploadFaceImage/UploadFaceImage";
import Submit from "../Submit/Submit";
import NavbarCom from "../NavbarCom";
import Footer from "../Footer";

const StepperForm = () => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [vRequired, setValueRequired] = useState(true);
  const [sdkToken, setSDKToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isUserCreated, setIsUserCreated] = useState(false);
  const [verifyState, setVerifyState] = useState({
    isComplete: undefined,
    checkId: "",
  });
  const [showStepper, setshowStepper] = useState(false);
  const [base64FrontImageData, setBase64FrontImageData] = useState(null);
  const [base64BackImageData, setBase64BackImageData] = useState(null);
  const [base64SelfieImageData, setBase64SelfieImageData] = useState(null);

  const [showOnfidoView, setShowOnfidoView] = useState(false);

  const nextStep = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = [
    {
      key: "Registration",
      title: "Registration",
      icon: <UserOutlined />,
    },
    {
      key: "Document Verification",
      title: "Document Verification",
      icon: <SolutionOutlined />,
    },

    {
      key: "Facial Recognition",
      title: "Facial Recognition",
      icon: <SmileOutlined />,
    },
    {
      key: "Completed",
      title: "Completed",
      icon: <FileDoneOutlined />,
    },
  ];

  return (
    <Layout>
      <NavbarCom />
      <div className="px-2">
        <div className="multi-step-parent-wrapper col col-md-11 col-lg-6 bg-white mx-auto  p-4 ">
          {current > 0 && (
            <button
              className="btn-light back-button btn ml-0 rounded-lg"
              style={{ margin: "0 8px" }}
              onClick={prev}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          )}
          {showStepper && <Steps
            size="small"
            className="bg-white  rounded-top pt-4 pb-2 border-bottom"
            current={current}
            items={items}
          />}
          <div className="">
            {current === 0 && (
              <Registration
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                setshowStepper={setshowStepper}
                showOnfidoView={showOnfidoView}
                setShowOnfidoView={setShowOnfidoView}
              />
            )}
            {current === 1 && (
              <SelectDocument
                nextStep={nextStep}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 2 && (
              <UploadFaceImage
                nextStep={nextStep}
                setCurrent={setCurrent}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                username={username}
                setUsername={setUsername}
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                vRequired={vRequired}
                setValueRequired={setValueRequired}
                sdkToken={sdkToken}
                setSDKToken={setSDKToken}
                loading={loading}
                setLoading={setLoading}
                isUserCreated={isUserCreated}
                setIsUserCreated={setIsUserCreated}
                verifyState={verifyState}
                setVerifyState={setVerifyState}
                base64BackImageData={base64BackImageData}
                setBase64BackImageData={setBase64BackImageData}
                base64FrontImageData={base64FrontImageData}
                setBase64FrontImageData={setBase64FrontImageData}
                base64SelfieImageData={base64SelfieImageData}
                setBase64SelfieImageData={setBase64SelfieImageData}
              />
            )}
            {current === 3 && <Submit />}
          </div>
          <div className="flex justify-between w-full">
            {/* {current < items.length - 1 && (
                          <Button type="default" onClick={nextStep}>
                              Next
                          </Button>
                      )} */}
            {/* {current === items.length - 1 && (
              <Button
                type="default"
                onClick={() => message.success("Processing complete!")}
              >
                Done
              </Button>
            )} */}
            {/* {current > 0 && (
              <button
                className="btn-dark btn ml-0 rounded"
                style={{ margin: "0 8px" }}
                onClick={prev}
              >
                Previous
              </button>
            )} */}
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default StepperForm;

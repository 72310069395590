import React, { useState } from "react";
import Accuant from "../Acuant";

const SelectDocument = ({
  nextStep,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  username,
  setUsername,
  email,
  setEmail,
  password,
  setPassword,
  vRequired,
  setValueRequired,
  sdkToken,
  setSDKToken,
  loading,
  setLoading,
  isUserCreated,
  setIsUserCreated,
  verifyState,
  setVerifyState,
  base64BackImageData,
  setBase64BackImageData,
  base64FrontImageData,
  setBase64FrontImageData,
  setBase64SelfieImageData,
}) => {
  const [showAccuant, setShowAccuant] = useState(false);

  const handleAccuantClick = () => {
    setShowAccuant(true);
  };

  return (
    <div className="form-main-body">
      {!showAccuant && (
        <div>
          <h1 className="verify-identity-title mt-3">Verify Your Identity</h1>
          <p className="font-normal text-base text-slate-500 mb-8">
            Please select the document type you want to use for verification.
          </p>
          <div
            className="d-flex flex-column g-4 document-type"
            placeholder="Select a document type"
          >
            <button
              className="btn custom-disabled-btns px-3 py-3 d-flex align-items-center btn-outline-primary text-left rounded-lg"
              value="driving_license"
              disabled
              aria-disabled="true"
            >
              <i className="fas document-btns-icons fa-id-badge fa-2x mr-2"></i>
              <div>
                <span className="fw-bold">Driving License</span>{" "}
              </div>
            </button>
            <button
              type="button"
              onClick={handleAccuantClick}
              className="btn d-flex  align-items-center px-3 btn-outline-primary py-3 text-left rounded-lg"
              value="Identity card"
              aria-pressed="true"
            >
              <i className="fas document-btns-icons fa-id-card fa-2x mr-2"></i>
              <div>
                <span className="fw-bold">Identity card</span>{" "}
              </div>
            </button>
            <button
              type="button"
              className="btn px-3 custom-disabled-btns d-flex align-items-center btn-outline-primary py-3 text-left rounded-lg"
              value="passport"
              disabled
              aria-disabled="true"
            >
              <i className="far document-btns-icons fa-address-book fa-2x mr-2"></i>
              <div>
                <span className="fw-bold" disabled>
                  Passport
                </span>{" "}
              </div>
            </button>
            <button
              type="button"
              className="btn px-3 custom-disabled-btns d-flex align-items-center btn-outline-primary py-3 text-left rounded-lg"
              value="residencePermit"
              disabled
            >
              <i className="far document-btns-icons fa-address-card fa-2x mr-2"></i>
              <div>
                <span className="fw-bold">Residence Permit</span>{" "}
              </div>
            </button>
          </div>
        </div>
      )}
      {showAccuant && (
        <Accuant
          nextStep={nextStep}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          username={username}
          setUsername={setUsername}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          vRequired={vRequired}
          setValueRequired={setValueRequired}
          sdkToken={sdkToken}
          setSDKToken={setSDKToken}
          loading={loading}
          setLoading={setLoading}
          isUserCreated={isUserCreated}
          setIsUserCreated={setIsUserCreated}
          verifyState={verifyState}
          setVerifyState={setVerifyState}
          base64BackImageData={base64BackImageData}
          setBase64BackImageData={setBase64BackImageData}
          base64FrontImageData={base64FrontImageData}
          setBase64FrontImageData={setBase64FrontImageData}
          setBase64SelfieImageData={setBase64SelfieImageData}
        />
      )}
    </div>
  );
};

export default SelectDocument;

import React, { Component } from 'react'

class Info extends Component {
    render() {
        return (
            <div>
                <section className="mt-n5" style={{ zIndex: '1' }}>
                    <div className="container">
                        <div className="row py-5 px-4 bg-primary rounded text-white services-hours">
                            <div className="col-sm">
                                <h2 className="text-white mb-4"><i className="fas fa-procedures mr-2 "></i> Emergency Service</h2>
                                <p style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                    An emergency department (ED), also known as an
                                    accident &amp;
                                    emergency department (A&amp;E), emergency room (ER), emergency ward (EW) or casualty
                                    department, is a
                                    medical treatment.
                                </p>
                                <h3 className="text-white mt-4">Call : +1-2345-3455-33</h3>
                            </div>
                            <div className="col-sm">
                                <h2 className="text-white mb-4">
                                    <i className="far fa-clock mr-2 "></i> Opening hours
                                </h2>
                                <p style={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                                    Far far away, behind the word mountains, far
                                    from the
                                    countries Vokalia and Consonantia.
                                </p>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item"><span>Mon - Fri</span> <span>08:00 - 21:00</span></li>
                                    <li className="list-group-item"><span>Sat - Sun</span> <span>08:00 - 19:00</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>



                <section className="pt-10 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="section-title" data-title="Our Services">Our Services</h2>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/lungs.png" alt="" />
                                <h3 className="font-weight-bold">Lung Diseases</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" className="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/heart.png" alt="" width="49" />
                                <h3 className="font-weight-bold">Heart Diseases</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/lungs.png" alt="" />
                                <h3 className="font-weight-bold">Orthopaedic</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/surgery.png" alt="" />
                                <h3 className="font-weight-bold">General Surgery</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/pharmacy.png" alt="" />
                                <h3 className="font-weight-bold">Pharmacy</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" className="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                            <div className="col-md-4 mb-5">
                                <img className="mb-4" src="./img/Injury.png" alt="" />
                                <h3 className="font-weight-bold">Sports Injury</h3>
                                <p className="my-3">
                                    One of the key classifications is between traditio nal small molecule
                                    drugs; usually
                                    derived from chemical synthesis.
                                </p>
                                <a href="#" className="btn btn-link px-0 d-inline-flex align-items-center">
                                    Learn More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" className="feather feather-arrow-right text-primary ml-2">
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                        <polyline points="12 5 19 12 12 19"></polyline>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <section class="counters mb-n5" style={{ zIndex: '1' }}>
                        <div class="container">
                            <div class="row py-5 px-4 bg-primary rounded text-white services-hours shadow shadow-lg"
                                id="counter">
                                <div class="col-sm-3 text-center mb-5 mb-sm-0">
                                    <span class="display-4 font-weight-bold counter-value"
                                        data-count="200">0</span>
                                    <h4 class="text-white">Doctors</h4>
                                </div>
                                <div class="col-sm-3 text-center mb-5 mb-sm-0">
                                    <span class="display-4 font-weight-bold counter-value"
                                        data-count="29000">0</span>
                                    <h4 class="text-white">Happy Patients</h4>
                                </div>
                                <div class="col-sm-3 text-center mb-5 mb-sm-0">
                                    <span class="display-4 font-weight-bold counter-value"
                                        data-count="1000">0</span>
                                    <h4 class="text-white">Medical Beds</h4>
                                </div>
                                <div class="col-sm-3 text-center">
                                    <span class="display-4 font-weight-bold counter-value"
                                        data-count="150">0</span>
                                    <h4 class="text-white">Winning Awards</h4>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section class="container-fluid">
                    <div class="row">
                        <div class="col-sm bg-light pt-5">
                            <div class="appointment-block-left float-right">
                                <h2 class="display-4 pt-5 mt-5 mb-4 font-weight-bold">Our Expertise</h2>
                                <div class="col-12 mb-5">
                                    <div class="row">
                                        <div class="col-auto">
                                            <img src="./img/beyondid-1.png" alt="" />
                                        </div>
                                        <div class="col pl-0">
                                            <h3 class="mb-3">Immune system</h3>
                                            <p>
                                                Drug discovery and drug development are complex and expensive endeavors
                                                undertaken
                                            </p>
                                            <a href="#"
                                                class="btn btn-link px-0 pt-0 d-inline-flex align-items-center">
                                                Learn More
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-arrow-right text-primary ml-2">
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                    <polyline points="12 5 19 12 12 19"></polyline>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12  mb-5">
                                    <div class="row">
                                        <div class="col-auto">
                                            <img src="./img/beyondid-1.png" alt="" />
                                        </div>
                                        <div class="col pl-0">
                                            <h3 class="mb-3">Antimalarial</h3>
                                            <p>
                                                Drug discovery and drug development are complex and expensive endeavors
                                                undertaken
                                            </p>
                                            <a href="#"
                                                class="btn btn-link px-0 pt-0 d-inline-flex align-items-center">
                                                Learn More
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-arrow-right text-primary ml-2">
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                    <polyline points="12 5 19 12 12 19"></polyline>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-5">
                                    <div class="row">
                                        <div class="col-auto">
                                            <img src="./img/beyondid-1.png" alt="" />
                                        </div>
                                        <div class="col pl-0">
                                            <h3 class="mb-3">Nutrition</h3>
                                            <p>
                                                Drug discovery and drug development are complex and expensive endeavors
                                                undertaken
                                            </p>
                                            <a href="#"
                                                class="btn btn-link px-0 pt-0 d-inline-flex align-items-center">
                                                Learn More
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-arrow-right text-primary ml-2">
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                    <polyline points="12 5 19 12 12 19"></polyline>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm  pt-5 appointment-container">
                            <div class="appointment-block">
                                <div class="col-12 mb-5">
                                    <h2 class="display-4 pt-5 mt-5 mb-4 text-white font-weight-bold">
                                        Make an appointment
                                    </h2>
                                    <p class="text-primary-light">
                                        We will send you a confirmation within 24 hours.<br />
                                        <span class="text-white font-weight-bold"> Emergency?</span> Call 1-2554-2356-33
                                    </p>
                                </div>
                                <form action="post" class="col-12">
                                    <div class="row">
                                        <div class="col-sm-6 form-group">
                                            <input class="form-control" type="text" placeholder="Full Name*" required=""
                                            />
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <input class="form-control" type="phone" placeholder="Phone*" required="" />
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <input class="form-control" type="email" placeholder="Email*" required="" />
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <select class="custom-select" aria-invalid="false">
                                                <option value="Lung Diseases">Lung Diseases</option>
                                                <option value="Heart Diseases">Heart Diseases</option>
                                                <option value="Orthopaedic">Orthopaedic</option>
                                                <option value="General Surgery">General Surgery</option>
                                                <option value="Pharmacy">Pharmacy</option>
                                                <option value="Sports Injury">Sports Injury</option>
                                                <option value="Fever &amp;amp; Flu">Fever &amp; Flu</option>
                                                <option value="Dental Service">Dental Service</option>
                                                <option value="Eye Care">Eye Care</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <input class="form-control" type="date" placeholder="Date*" required="" />
                                        </div>
                                        <div class="col-sm-6 form-group">
                                            <input class="form-control" type="text" placeholder="Time*" required="" />
                                        </div>
                                        <div class="col-12 form-group">
                                            <textarea class="form-control" name="" id="" cols="30" rows="5"
                                                placeholder="Message"></textarea>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-block btn-primary-soft mb-5">Submit Now</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <ul class="nav nav-pills d-flex flex-nowrap nav-fill-custom shadow" id="pills-tab" role="tablist">
                        <li class="nav-item w-100" role="presentation">
                            <div class="nav-link p-4 h-100 text-left active rounded-0 active" id="pills-home-tab"
                                data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home"
                                aria-selected="true">
                                <div class="d-flex">
                                    <i class="fas fa-minus mt-1 mr-3"></i>
                                    <div>
                                        <p class="pills-tab-title display-4 mb-1"> Hospital Introduction</p>
                                        <p class="mb-0">See Video Introduction</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item w-100" role="presentation">
                            <div class="nav-link p-4 h-100 text-left  rounded-0" id="pills-profile-tab"
                                data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile"
                                aria-selected="false">
                                <div class="d-flex">
                                    <i class="fas fa-minus mt-1 mr-3"></i>
                                    <div>
                                        <p class="pills-tab-title display-4 mb-1"> Why Choose Us</p>
                                        <p class="mb-0">Listen from real patients</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item w-100" role="presentation">
                            <div class="nav-link p-4 h-100  text-left  rounded-0" id="pills-contact-tab"
                                data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact"
                                aria-selected="false">
                                <div class="d-flex">
                                    <i class="fas fa-minus mt-1 mr-3"></i>
                                    <div>
                                        <p class="pills-tab-title display-4 mb-1">Our innovation</p>
                                        <p class="mb-0">Our dedicated research team</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item w-100" role="presentation">
                            <div class="nav-link p-4 h-100  text-left  rounded-0" id="pills-contact-tab"
                                data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact"
                                aria-selected="false">
                                <div class="d-flex">
                                    <i class="fas fa-minus mt-1 mr-3"></i>
                                    <div>
                                        <p class="pills-tab-title display-4 mb-1">Give Back</p>
                                        <p class="mb-0">Donation programme</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane pt-15 fade show active" id="pills-home" role="tabpanel"
                            aria-labelledby="pills-home-tab">
                            <div class="row p-5">
                                <div class="col-auto">
                                    <h4 class="title text-white" data-title="About Us">About Us</h4>
                                    <img src="./img/appointment-bg.jpg"
                                        class="img-fluid img-thumbnail border-0 p-0 shadow" style={{ maxWidth: "300px" }}
                                        alt="" />
                                </div>
                                <div class="col-sm col-sm pl-md-5">
                                    <h4 class="mb-4 text-white">
                                        Historically, drugs were discovered through identifying
                                        the active ingredient from traditional remedies or by serendipitous discovery.
                                        Later chemical libraries of synthetic small molecules.
                                    </h4>
                                    <p class="mb-5">
                                        Modern drug discovery involves the identification of screening hits,
                                        medicinal chemistry and optimization of those hits to increase the affinity,
                                        selectivity (to reduce the potential of side effects), efficacy/potency,
                                        metabolic stability (to increase the half-life), and oral bioavailability. Once
                                        a compound that fulfills all of these requirements.
                                    </p>
                                    <a href="#"
                                        class="btn btn-primary-soft rounded d-inline-flex align-items-center">
                                        Learn More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-arrow-right ml-2">
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                            <polyline points="12 5 19 12 12 19"></polyline>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane pt-15 fade" id="pills-profile" role="tabpanel"
                            aria-labelledby="pills-profile-tab">
                            <div class="row p-5">
                                <div class="col-auto">
                                    <h4 class="title text-white">Why Us</h4>
                                    <img src="./img/health-care-banner1.jpg"
                                        class="img-fluid img-thumbnail border-0 p-0 shadow" style={{ maxWidth: "300px" }}
                                        alt="" />
                                </div>
                                <div class="col-sm col-sm pl-md-5">
                                    <h4 class="mb-4 text-white">
                                        Historically, drugs were discovered through identifying
                                        the active ingredient from traditional remedies or by serendipitous discovery.
                                        Later chemical libraries of synthetic small molecules.
                                    </h4>
                                    <p class="mb-5">
                                        Modern drug discovery involves the identification of screening hits,
                                        medicinal chemistry and optimization of those hits to increase the affinity,
                                        selectivity (to reduce the potential of side effects), efficacy/potency,
                                        metabolic stability (to increase the half-life), and oral bioavailability. Once
                                        a compound that fulfills all of these requirements.
                                    </p>
                                    <a href="#"
                                        class="btn btn-primary-soft rounded d-inline-flex align-items-center">
                                        Learn More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-arrow-right ml-2">
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                            <polyline points="12 5 19 12 12 19"></polyline>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane  pt-15 fade" id="pills-contact" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="row p-5">
                                <div class="col-auto">
                                    <h4 class="title text-white">Our innovation</h4>
                                    <img src="./img/appointment-bg.jpg"
                                        class="img-fluid img-thumbnail border-0 p-0 shadow" style={{ maxWidth: "300px" }}
                                        alt="" />
                                </div>
                                <div class="col-sm col-sm pl-md-5">
                                    <h4 class="mb-4 text-white">
                                        Historically, drugs were discovered through identifying
                                        the active ingredient from traditional remedies or by serendipitous discovery.
                                        Later chemical libraries of synthetic small molecules.
                                    </h4>
                                    <p class="mb-5">
                                        Modern drug discovery involves the identification of screening hits,
                                        medicinal chemistry and optimization of those hits to increase the affinity,
                                        selectivity (to reduce the potential of side effects), efficacy/potency,
                                        metabolic stability (to increase the half-life), and oral bioavailability. Once
                                        a compound that fulfills all of these requirements.
                                    </p>
                                    <a href="#"
                                        class="btn btn-primary-soft rounded d-inline-flex align-items-center">
                                        Learn More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-arrow-right ml-2">
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                            <polyline points="12 5 19 12 12 19"></polyline>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane pt-15 fade" id="pills-contact" role="tabpanel"
                            aria-labelledby="pills-contact-tab">
                            <div class="row p-5">
                                <div class="col-auto">
                                    <h4 class="title text-white">Give Back</h4>
                                    <img src="./img/health-care-banner1.jpg"
                                        class="img-fluid img-thumbnail border-0 p-0 shadow" style={{ maxWidth: "300px" }}
                                        alt="" />
                                </div>
                                <div class="col-sm col-sm pl-md-5">
                                    <h4 class="mb-4 text-white">
                                        Historically, drugs were discovered through identifying
                                        the active ingredient from traditional remedies or by serendipitous discovery.
                                        Later chemical libraries of synthetic small molecules.
                                    </h4>
                                    <p class="mb-5">
                                        Modern drug discovery involves the identification of screening hits,
                                        medicinal chemistry and optimization of those hits to increase the affinity,
                                        selectivity (to reduce the potential of side effects), efficacy/potency,
                                        metabolic stability (to increase the half-life), and oral bioavailability. Once
                                        a compound that fulfills all of these requirements.
                                    </p>
                                    <a href="#"
                                        class="btn btn-primary-soft rounded d-inline-flex align-items-center">
                                        Learn More
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-arrow-right ml-2">
                                            <line x1="5" y1="12" x2="19" y2="12"></line>
                                            <polyline points="12 5 19 12 12 19"></polyline>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="employee-section pt-10">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="section-title" data-title="Our Doctors">Our Doctors</h2>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-md-4 p-md-3">
                                <div class="card mb-4 mb-md-0">
                                    <div class="overflow-hidden">
                                        <img src="https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/medicalstaff.jpg"
                                            class="card-img-top" alt="..." />
                                    </div>
                                    <div class="card-body text-center">
                                        <h5 class="card-title text-primary mb-0">Joshua Clark</h5>
                                        <p class="card-text">Anesthesiologist</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-md-3">
                                <div class="card mb-4 mb-md-0">
                                    <div class="overflow-hidden">
                                        <img src="https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/medicalstaff.jpg"
                                            class="card-img-top" alt="..." />
                                    </div>
                                    <div class="card-body text-center">
                                        <h5 class="card-title text-primary mb-0">Joshua Clark</h5>
                                        <p class="card-text">Anesthesiologist</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 p-md-3">
                                <div class="card mb-4 mb-md-0">
                                    <div class="overflow-hidden">
                                        <img src="https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/medicalstaff.jpg"
                                            class="card-img-top" alt="..." />
                                    </div>
                                    <div class="card-body text-center">
                                        <h5 class="card-title text-primary mb-0">Joshua Clark</h5>
                                        <p class="card-text">Anesthesiologist</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>









                <section class="pt-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h2 class="section-title" data-title="Certificates & Standards">
                                    Certificates & Standards
                                </h2>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col">
                                <img class="img-fluid" src="./img/cert-1.png" alt="" />
                            </div>
                            <div class="col">
                                <img class="img-fluid" src="./img/cert-2.png" alt="" />
                            </div>
                            <div class="col">
                                <img class="img-fluid" src="./img/cert-3.png" alt="" />
                            </div>
                            <div class="col">
                                <img class="img-fluid" src="./img/cert-1.png" alt="" />
                            </div>
                            <div class="col">
                                <img class="img-fluid" src="./img/cert-2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
                <section class="mb-10 pt-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-sm" style={{ background: "url(./img/Girl-talk.jpg)", backgroundSize: "cover" }}>
                            </div>
                            <div class="col-sm bg-white py-5 shadow shadow-right-lg">
                                <div class="p-5">
                                    <div class="display-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="87.5"
                                            viewBox="0 0 100 87.5" class="mb-5">
                                            <g id="Group_219" data-name="Group 219" transform="translate(0 -32)">
                                                <path fill="#bfd6f8" id="Path_92" data-name="Path 92"
                                                    d="M322.375,75.75H306.75V63.25a12.512,12.512,0,0,1,12.5-12.5h1.563a4.676,4.676,0,0,0,4.688-4.664v-9.4A4.676,4.676,0,0,0,320.836,32H319.25A31.25,31.25,0,0,0,288,63.25v46.875a9.375,9.375,0,0,0,9.375,9.375h25a9.375,9.375,0,0,0,9.375-9.375v-25A9.375,9.375,0,0,0,322.375,75.75Z"
                                                    transform="translate(-231.75)" />
                                                <path fill="#17449e" id="Path_93" data-name="Path 93"
                                                    d="M34.375,75.75H18.75V63.25a12.512,12.512,0,0,1,12.5-12.5h1.563A4.676,4.676,0,0,0,37.5,46.086v-9.4A4.676,4.676,0,0,0,32.836,32H31.25A31.25,31.25,0,0,0,0,63.25v46.875A9.375,9.375,0,0,0,9.375,119.5h25a9.375,9.375,0,0,0,9.375-9.375v-25A9.375,9.375,0,0,0,34.375,75.75Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <p class="">
                                        Historically, drugs were discovered through identifying the active
                                        ingredient from traditional remedies or by serendipitous discovery. Later
                                        chemical libraries of synthetic small molecules.
                                    </p>
                                    <h4 class="author-name text-primary font-weight-bold mt-5">
                                        Mildred Payne
                                    </h4>
                                    <div class="author-post">
                                        ALlergist
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 rounded-bottom p-4 background-gradient-right">
                                <div class="row align-items-center">
                                    <div class="col-sm">
                                        <h2 class="text-white">Subscribe to our newsletter</h2>
                                        <p class="text-primary-light">
                                            Community Memorial Hospital is the ONLY hospital
                                            in the state of New York to achieve One of Healthgrades America’s 100 Best
                                            Hospitals for Joint Replacement in 2016.
                                        </p>
                                    </div>
                                    <div class="col-sm">
                                        <div class="input-group">
                                            <input type="email" class="form-control" placeholder="Your email address"
                                                aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                            <a type="button" class="input-group-append">
                                                <span class="input-group-text bg-primary-soft text-white font-weight-bold"
                                                    id="basic-addon2"><small>Subscribe</small></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="feature-section">
                    <div class="container-fluid px-0" />
                    <div class="row text-center no-gutters">
                        <div class="col-md-4">
                            <div class="card h-100 shadow-none  rounded-0" style={{ backgroundCcolor: "#113377 ;" }}>
                                <div class="card-body  p-5 text-white">
                                    <img src="./img/col-feature-1.png" alt="" />
                                    <h3 class="text-truncate mt-4 text-white font-weight-bold">
                                        Best Monitoring
                                        System
                                    </h3>
                                    <p class="mb-0">
                                        Despite advances in technology and understanding of biological
                                        systems, drug discovery is still a lengthy, expensive.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card h-100 shadow-none rounded-0 card-bg">
                                <div class="card-body p-5 text-white">
                                    <img src="./img/col-feature-2.png" alt="" />
                                    <h3 class="text-truncate mt-4 text-white font-weight-bold">
                                        Advanced Operating
                                        Room
                                    </h3>
                                    <p class="mb-0">
                                        Despite advances in technology and understanding of biological
                                        systems, drug discovery is still a lengthy, expensive.

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card h-100 shadow-none bg-primary rounded-0">
                                <div class="card-body  p-5 text-white">
                                    <img src="./img/col-feature-3.png" alt="" />
                                    <h3 class="text-truncate mt-4 text-white font-weight-bold">
                                        Only Best Doctors

                                    </h3>
                                    <p class="mb-0">
                                        Despite advances in technology and understanding of biological
                                        systems, drug discovery is still a lengthy, expensive.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="bg-img-cover overlay py-15"
                    style={{
                        backgroundImage: "url('https://cdn-zartan.s3.us-east-2.amazonaws.com/static/img/medicalstaff.jpg')", backgroundAttachment: "fixed"
                    }}>
                    <div class="container position-relative">
                        <div class="row justify-content-center">
                            <div class="col-lg-10">
                                <div class="display-4 text-center font-weight-bold text-white">
                                    With medical healthcare
                                    and long
                                    term care
                                    facilities from
                                    coast to coast, our facilities are uniquely qualified as a leader in
                                    the field of healthcare and hospitalization..
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="py-10">
                    <div class="container">
                        <h4 class="section-title mb-5" data-title="Testimonial">Testimonial</h4>
                        <div id="carouselExampleIndicators1" class="carousel carousel-testimonial slide"
                            data-ride="carousel">
                            <ol class="carousel-indicators">
                                <li class="bg-dark" data-target="#carouselExampleIndicators1" data-slide-to="0"
                                    class="active"></li>
                                <li class="bg-dark" data-target="#carouselExampleIndicators1" data-slide-to="1"></li>
                                <li class="bg-dark" data-target="#carouselExampleIndicators1" data-slide-to="2"></li>
                            </ol>
                            <div class="carousel-inner p-10 bg-light rounded">
                                <div class="mb-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" viewBox="0 0 100 87.5">
                                        <g id="Group_219" data-name="Group 219" transform="translate(0 -32)">
                                            <path fill="#bfd6f8" id="Path_92" data-name="Path 92"
                                                d="M322.375,75.75H306.75V63.25a12.512,12.512,0,0,1,12.5-12.5h1.563a4.676,4.676,0,0,0,4.688-4.664v-9.4A4.676,4.676,0,0,0,320.836,32H319.25A31.25,31.25,0,0,0,288,63.25v46.875a9.375,9.375,0,0,0,9.375,9.375h25a9.375,9.375,0,0,0,9.375-9.375v-25A9.375,9.375,0,0,0,322.375,75.75Z"
                                                transform="translate(-231.75)"></path>
                                            <path fill="#17449e" id="Path_93" data-name="Path 93"
                                                d="M34.375,75.75H18.75V63.25a12.512,12.512,0,0,1,12.5-12.5h1.563A4.676,4.676,0,0,0,37.5,46.086v-9.4A4.676,4.676,0,0,0,32.836,32H31.25A31.25,31.25,0,0,0,0,63.25v46.875A9.375,9.375,0,0,0,9.375,119.5h25a9.375,9.375,0,0,0,9.375-9.375v-25A9.375,9.375,0,0,0,34.375,75.75Z">
                                            </path>
                                        </g>
                                    </svg>
                                </div>
                                <div class="carousel-item active">
                                    <h5>
                                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem reprehenderit
                                        dolor
                                        quam obcaecati sunt? Magnam quasi libero eveniet rem nisi, porro quidem impedit
                                        saepe omnis quos ullam voluptatem sint inventore!
                                    </h5>
                                    <div class="row mt-5 align-items-center">
                                        <div class="col-auto">
                                            <img src="./img/appointment-bg.jpg"
                                                class="rounded-circle shadow shadow-lg" width="70" height="70" alt="" />
                                        </div>
                                        <div class="col">
                                            <p class="font-weight-bold text-primary mb-0">Mildred Payne</p>
                                            <p class="text-dark">Allergist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <h5>
                                        Lorem ipsum dolor sit aipisicing elit. Quidem reprehenderit
                                        dolor
                                        quam obcaecati sunt? Magnam quasi libero eveniet rem nisi, porro quidem impedit
                                        saepe omnis quos ullam voluptatem sint inventore!
                                    </h5>
                                    <div class="row mt-5 align-items-center">
                                        <div class="col-auto">
                                            <img src="./img/appointment-bg.jpg"
                                                class="rounded-circle shadow shadow-lg" width="70" height="70" alt="" />
                                        </div>
                                        <div class="col">
                                            <p class="font-weight-bold text-primary mb-0">Mildred Payne</p>
                                            <p class="text-dark">Allergist</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="carousel-item">
                                    <h5>
                                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quidem reprehenderit
                                        dolor
                                        quam obcae eveniet rem nisi, porro quidem impedit
                                        saepe omnis quos ullam voluptatem sint inventore!
                                    </h5>
                                    <div class="row mt-5 align-items-center">
                                        <div class="col-auto">
                                            <img src="./img/appointment-bg.jpg"
                                                class="rounded-circle shadow shadow-lg" width="70" height="70" alt="" />
                                        </div>
                                        <div class="col">
                                            <p class="font-weight-bold text-primary mb-0">Mildred Payne</p>
                                            <p class="text-dark">Allergist</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators1" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators1" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </section>

            </div >
        )
    }
}


export default Info;
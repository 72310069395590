import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import oktaSignInConfig from '../OktaSignInConfig';


const OktaSignInWidget = ({ onSuccess, onError }) => {
    const widgetRef = useRef();
    useEffect(() => {
        if (!widgetRef.current) {
            return false;
        }

        const widget = new OktaSignIn({
            baseUrl: oktaSignInConfig.baseUrl,
            clientId: oktaSignInConfig.clientId,
            redirectUri: oktaSignInConfig.redirectUri,
            logo: "https://img.freepik.com/premium-psd/instagram-logo-social-media-3d_304434-558.jpg?w=2000",
        });
        widget
            .showSignInToGetTokens({ el: widgetRef.current })
            .then(onSuccess)
            .catch(onError);

        return () => widget.remove();
    }, [onSuccess, onError]);

    return (<div ref={widgetRef} />);
};

export default OktaSignInWidget;
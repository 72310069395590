import React from "react";

const Submit = () => {
  return (
    <div className="d-flex completed-wrapper flex-direction-column">
      <h1 className="completed-title">Thank You</h1>
      <i className="fas text-success fa-user-check fa-2x"></i>
      <p className="font-normal text-base text-slate-500">
        You have been successfully verified. Please Login to continue.
      </p>
    </div>
  );
};

export default Submit;
